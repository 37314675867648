<template>
<div class="landing_page">
    <!-- Banner Section  -->
    <section class="banner">
        <div class="container">
            <div class="row align-items-center flex-column-reverse flex-md-row">
                <div class="col-md-6">
                    <div class="banner_content text-center text-md-start">
                        <h1 class="m-0 mb-2 mb-md-4">
                           No. of TACC Users  {{TotalUser}}
                        </h1>
                        <h1 class="m-0 mb-2 mb-md-4">
                            Trusted by 15M+ People Globally for Cryptocurrency trading
                        </h1>
                        <p class="m-0 mb-2 mb-md-4" >
                            Buy & sell bitcoin, cryptocurrencies instantly with just $10 
                        </p>
                        
                        <div class="banner_btn">
                            <router-link class="btn landing-button" to="/register">Get Started</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 mb-5 mb-md-0">
                    <div class="banner_img text-center">
                        <img src="../assets/images/landing_page/banner_img.png" class="img-fluid" alt="img" />
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- posters  -->
    <section class="posters">
        <div class="container">
            <TaccPosters />
        </div>
    </section>

    <!-- Live Chart  -->
    <section class="live_chart">
        <div class="container">
            <LiveChart :data="coin_list" :loading="loading" />
        </div>
    </section>

    <!-- Coin List  -->
    <section class="coin_list">
        <div class="container">
            <div class="row">
                <div class="col-md-12 text-center">
                    <MainHeading big_heading="Coins List" small_heading="List of crypto Coins" />
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="coin_list_table table-responsive">
                        <table class="table text-center">
                            <thead>
                                <tr>
                                    <th>Currency</th>
                                    <th>Price</th>
                                    <th>Change</th>
                                    <th>Buy</th>
                                    <th>Sell</th>
                                </tr>
                            </thead>
                            <tbody v-if="loading">
                                <tr v-for="i in 8" :key="i">
                                    <td v-for="i in 5" :key="i" width="150px">
                                        <Skeletor />
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr v-for="(list, index) in coin_list" :key="index">
                                    <td>{{ list.currency }}/{{ list.pair_with }}</td>
                                    <td>&dollar; {{ parseFloat(list.price) }}</td>
                                    <td :style="list.change > 0 ? 'color:green' : 'color:red'">
                                        {{ parseFloat(list.change).toFixed(2) }} %
                                    </td>
                                    <td>

                                        <button class="btn btn-green px-4" @click="Submit( list)">Buy</button>
                                    </td>
                                    <td>
                                        <button class="btn btn-red px-4" @click="Submit( list)">Sell</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- Why Tacc  -->
    <section class="why_tacc">
        <div class="container">
            <div class="row">
                <div class="col-md-12 text-center">
                    <MainHeading big_heading="Why" small_heading="Why Abundance ?" />
                </div>
            </div>
            <div class="row justify-content-center align-items-center">
                <div class="col-sm-6 col-md-6 col-lg-5 col-xl-3 col-xxl-3" v-for="(why, index) in why_tacc" :key="index">
                    <WhyTacc :why_tacc="why" />
                </div>
            </div>
        </div>
    </section>

    <!-- Features Tacc  -->
    <section class="feature_tacc">
        <div class="container">
            <div class="row">
                <div class="col-md-12 text-center">
                    <MainHeading big_heading="features" small_heading="features of Abundance" />
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-sm-10 col-md-6" v-for="(feature, index) in features_tacc" :key="index">
                    <FeatureTacc :features_tacc="feature" />    

                </div>
            </div>
        </div>
    </section>

    <!-- P2P Tacc  -->
    <section class="p2p_tacc">
        <div class="container">
            <div class="row">
                <div class="col-md-12 text-center">
                    <MainHeading big_heading="P2P" small_heading="HOW P2P Works" />
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-md-12">
                    <P2pWorking />
                </div>
            </div>
        </div>
    </section>

    <!-- P2P Benefits Tacc  -->
    <section class="p2p_tacc_benefits">
        <div class="container">
            <div class="row">
                <div class="col-md-12 text-center">
                    <MainHeading big_heading="Benefits" small_heading="benefits of P2P" />
                </div>
            </div>
            <div class="row justify-content-center p2p_tacc_benefits_cus">
                <div class="col-sm-6 col-md-4 col-lg-3" v-for="(benefit, index) in p2p_benefits" :key="index">
                    <BenefitsP2p :p2p_benefits="benefit" />
                </div>
            </div>
        </div>
    </section>

    <!-- App Download Tacc  -->
    <section class="app_download_tacc">
        <div class="container">
            <div class="row">
                <div class="col-md-12 text-center">
                    <MainHeading big_heading="Get Started" small_heading="Download Abundance" />
                </div>
            </div>
            <div class="
            row
            justify-content-center
            align-items-center
            flex-column-reverse flex-md-row
          ">
                <div class="col-md-6">
                    <div class="tacc_download_link">
                        <ul class="
                  list-group list-group-horizontal
                  justify-content-center
                  mb-3
                ">
                            <li class="list-group-item bg-transparent border-0">
                                <a href="#"><img src="../assets/images/landing_page/download-1.png" class="img-fluid" alt="img" /></a>
                            </li>
                            <li class="list-group-item bg-transparent border-0">
                                <a href="#"><img src="../assets/images/landing_page/download-2.png" class="img-fluid" alt="img" /></a>
                            </li>
                        </ul>
                        <ul class="list-group list-group-horizontal justify-content-center">
                            <li class="list-group-item bg-transparent border-0">
                                <a href="#"><img src="../assets/images/landing_page/download-3.png" class="img-fluid" alt="img" /></a>
                            </li>
                            <li class="list-group-item bg-transparent border-0">
                                <a href="#"><img src="../assets/images/landing_page/download-4.png" class="img-fluid" alt="img" /></a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-6 mb-4 mb-md-0">
                    <div class="tacx_download_img text-center">
                        <img src="../assets/images/landing_page/download.png" class="img-fluid" alt="img" />
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- Trading Tacc  -->
    <section class="trading_tacc">
        <div class="container">
            <div class="row">
                <div class="col-md-12 text-center">
                    <MainHeading big_heading="Trading" small_heading="Buy . Sell . Trade" />
                </div>
            </div>
            <div class="row justify-content-center align-items-center trading_tacc_cus">
                <div class="col-md-12 col-lg-4" v-for="(trading, index) in trading_tacc" :key="index">
                    <TradeTacc :trading_tacc="trading" />
                </div>
            </div>
        </div>
    </section>

    <!-- Get In touch Tacc  -->
    <section class="get_in_touch">
        <div class="container">
            <div class="row">
                <div class="col-md-12 text-center">
                    <MainHeading big_heading="Get in touch" small_heading="Stay In touch" />
                </div>
            </div>
            <div class="row justify-content-around align-items-stretch h-100">
                <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3" v-for="(get, index) in get_touch" :key="index">
                    <GetTouch :get_touch="get" />
                </div>
            </div>
        </div>
    </section>

    <!-- FAQ Tacc  -->
    <section class="faq_tacc">
        <div class="container">
            <div class="row">
                <div class="col-md-12 text-center">
                    <MainHeading big_heading="Questions" small_heading="frequently ASKED QUESTIONS" />
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-md-8">
                    <div class="faq">
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item" v-for="(faq, index) in faqs" :key="index">
                                <h2 class="accordion-header" :id="'headingTwo' + index">
                                    <button class="accordion-button shadow-none collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapseTwo' + index" aria-expanded="false" :aria-controls="'collapseTwo' + index">
                                        {{ faq.question }}
                                    </button>
                                </h2>
                                <div :id="'collapseTwo' + index" class="accordion-collapse collapse" :aria-labelledby="'headingTwo' + index" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        {{ faq.answer }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
</template>

<script>
import MainHeading from "@/components/landing_page/MainHeading.vue";
import WhyTacc from "@/components/landing_page/WhyTacc";
import FeatureTacc from "@/components/landing_page/FeatureTacc";
import P2pWorking from "@/components/landing_page/P2pWorking";
import BenefitsP2p from "@/components/landing_page/BenefitsP2p";
import TradeTacc from "@/components/landing_page/TradeTacc";
import GetTouch from "@/components/landing_page/GetTouch";
import TaccPosters from "@/components/landing_page/TaccPosters";
import LiveChart from "@/components/landing_page/LiveChart";
import exchange_data from "@/assets/json/exchange_data";
import ApiClass from "@/api/api";

export default {
    name: "HomeView",
    components: {
        MainHeading,
        TaccPosters,
        LiveChart,
        WhyTacc,
        FeatureTacc,
        P2pWorking,
        BenefitsP2p,
        TradeTacc,
        GetTouch,
    },
    data: () => ({
        coin_list: [],
        tickers: [],
        why_tacc: exchange_data.why_tacc,
        features_tacc: exchange_data.features_tacc,
        p2p_benefits: exchange_data.p2p_benefits,
        trading_tacc: exchange_data.trading_tacc,
        get_touch: exchange_data.get_touch,
        faqs: exchange_data.faqs,
        loading: true,
        slug: "",
        TotalUser:0
    }),

    mounted() {
        this.ChartGet();
        this.TotalUsers();

    },
    methods: {

        Submit(slug) {
            this.$router.push({ path: '/exchange', query: {s: slug.currency+slug.pair_with}})

        },

        async ChartGet() {
            let response = await ApiClass.getNodeRequest("list-crypto/get", false);

            if (response.data.status_code == 1) {
                this.coin_list = response.data.data.USDT.slice(0, 8);
                this.loading = false;
                this.tickers = response.data.tickers.slice(0, 8);
            }
            const ticker_subs = this.tickers.map((v) => v.toLowerCase() + "@ticker");
            let $this = this;
            const ws = new WebSocket("wss://stream.binance.com:9443/ws");
            const data = {
                method: "SUBSCRIBE",
                params: ticker_subs,
                id: 1,
            };
            ws.onopen = function () {
                // Web Socket is connected, send data using send()
                ws.send(JSON.stringify(data));
            };
            ws.onmessage = function (evt) {
                var received_msg = JSON.parse(evt.data);
                // console.log(received_msg.s);
                $this.coin_list.filter((CoinList) => {
                    if (CoinList.symbol == received_msg.s) {
                        CoinList.change = received_msg.P;
                        CoinList.price = received_msg.c;
                    }
                });
            };
            ws.onclose = function () {
                console.log("Home Connection is closed...");
            };
            ws.onerror = function (evt) {
                console.log(evt);
            };
        },
        async TotalUsers() {
            let response = await ApiClass.getNodeRequest("dashboard/total-users", false);
                console.log({totalu : response?.data?.data?.totalUsers});
                
            if (response.data.status_code == 1) {
                this.TotalUser = response?.data?.data?.totalUsers;
                this.loading = false;
                return ;
                
            }
            
        },
    },
};
</script>

<style scoped>
/************************************ Landing Button CSS*************************************/

.landing-button {
    background-color: var(--tc-secondary-color) !important;
    border-color: var(--tc-secondary-color);
    color: var(--tc-white) !important;
    font-size: var(--tc-fs-14) !important;
    box-shadow: 0 0 8px var(--tc-secondary-color) !important;
    border-radius: 0 10px 0 10px;
    font-family: var(--tc-ff-medium);
    padding: 8px 25px;
}

.landing-button:hover {
    background-color: var(--tc-secondary-light-color) !important;
    border-color: var(--tc-secondary-color) !important;
    color: var(--tc-white) !important;
    box-shadow: none !important;
}

.landing_page {
    background-color: var(--tc-white);
}

/************************************ Common CSS*************************************/

section {
    padding-bottom: 80px;
}

/************************************ Banner section CSS*************************************/

section.banner {
    background-image: url(../assets/images/landing_page/banner_bg.png);
    width: 100%;
    min-height: 680px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    padding: 0;
}

.banner_content h1 {
    font-size: 30px;
    font-family: var(--tc-ff-medium);
    color: var(--tc-white);
}

.banner_content p {
    font-family: var(--tc-ff-regular);
    color: var(--tc-white);
    font-size: var(--tc-fs-14);
}

/************************************ Poster section CSS*************************************/

section.posters {
    width: 100%;
    min-height: 174px;
    position: relative;
    background-color: var(--tc-bg-color);
    padding: 20px;
}

section.posters::before {
    position: absolute;
    content: "";
    bottom: 0px;
    left: 0;
    background-color: rgba(0, 0, 0, 1);
    filter: brightness(7) blur(2px) opacity(0.03);
    width: 100%;
    min-height: 100%;
}

/************************************ Live Chart section CSS*************************************/

section.live_chart {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    text-align: center;
    padding: 30px 0;
}

/************************************ Coin List Section*************************************/

.btn-green {
    background: var(--tc-green) !important;
    border-color: var(--tc-green) !important;
    box-shadow: var(--tc-shadow-1) !important;
    font-size: var(--tc-fs-14) !important;
    border-radius: var(--tc-border-radius);
    font-family: var(--tc-ff-medium) !important;
    color: var(--tc-white) !important;
}

.btn-green:hover {
    background: var(--tc-light-green) !important;
    border-color: var(--tc-light-green) !important;
    box-shadow: var(--tc-shadow-1) !important;
    border-radius: var(--tc-border-radius) !important;
    color: var(--tc-white) !important;
}

.btn-red {
    background: var(--tc-red) !important;
    border-color: var(--tc-red) !important;
    box-shadow: var(--tc-shadow-1) !important;
    font-size: var(--tc-fs-14) !important;
    border-radius: var(--tc-border-radius);
    font-family: var(--tc-ff-medium) !important;
    color: var(--tc-white) !important;
}

.btn-red:hover {
    background: var(--tc-light-red) !important;
    border-color: var(--tc-light-red) !important;
    box-shadow: var(--tc-shadow-1) !important;
    border-radius: var(--tc-border-radius) !important;
    color: var(--tc-white) !important;
}

.coin_list_table table {
    margin-bottom: 0;
    border: 1px solid #462601;
    border-radius: 50px;
}

.coin_list_table table thead {
    background-image: linear-gradient(to right, #5a3000, #2b1702);
    color: var(--tc-white);
}

.coin_list_table table thead tr th {
    white-space: nowrap;
    width: 150px;
}

.coin_list_table table tbody tr td {
    border-bottom: 0;
    white-space: nowrap;
    width: 150px;
}

.coin_list_table table tbody tr:hover {
    background-color: #dddddd;
    cursor: pointer;
}

/************************************Why TACC Section*************************************/

section.why_tacc {
    background-image: url(../assets/images/landing_page/why.png);
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 637;
    display: flex;
    align-items: center;
}

/************************************P2P Section*************************************/

section.p2p_tacc {
    background-image: url(../assets/images/landing_page/p2p_bg.png);
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 709px;
    display: flex;
    align-items: center;
}

/************************************ Benefits Section*************************************/

.p2p_tacc_benefits_cus {
    position: relative;
}

.p2p_tacc_benefits_cus::before {
    position: absolute;
    content: "";
    top: -100px;
    left: 20px;
    background-image: url(../assets/images/landing_page/p2p_benefits-before.png);
    width: 380px;
    height: 192px;
}

/************************************App download Section*************************************/

section.app_download_tacc {
    background-image: url(../assets/images/landing_page/why.png);
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 637;
    display: flex;
    align-items: center;
}

.tacc_download_link a img {
    transition: transform 1s ease;
    filter: drop-shadow(0 4px 6px);
    color: rgba(255, 255, 255, 0.3);
}

.tacc_download_link a img:hover {
    transform: scale(1.2);
    transition: transform 1s ease;
}

/************************************ Trade Section*************************************/

.trading_tacc_cus {
    position: relative;
}

.trading_tacc_cus::before {
    position: absolute;
    content: "";
    top: -30px;
    left: -50px;
    background-image: url(../assets/images/landing_page/trade-shape-1.png);
    background-repeat: no-repeat;
    width: 149px;
    height: 103px;
}

.trading_tacc_cus::after {
    position: absolute;
    content: "";
    bottom: -10px;
    right: -28px;
    background-image: url(../assets/images/landing_page/trade-shape-1.png);
    background-repeat: no-repeat;
    width: 149px;
    height: 103px;
}

/************************************ Get In Touch Section*************************************/

section.get_in_touch {
    background-image: url(../assets/images/landing_page/get_in_bg.png);
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 594px;
    display: flex;
    align-items: center;
}

/************************************ FAQ Section*************************************/

.accordion {
    box-shadow: inset 0 4px 8px rgb(0 0 0 / 16%);
}

.accordion-button:not(.collapsed) {
    color: var(--tc-white);
    background-color: var(--tc-secondary-color);
    box-shadow: unset;
}

.accordion-button::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url(../assets/images/landing_page/arrow.png) !important;
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform 0.2s ease-in-out;
}

/************************************Media CSS*************************************/
@media all and (min-width: 992px) and (max-width: 1199px) {
    section.banner {
        min-height: 500px;
    }

    .trading_tacc_cus::before,
    .trading_tacc_cus::after {
        display: none;
    }
}

@media all and (min-width: 768px) and (max-width: 991px) {
    .banner_content h1 {
        font-size: 24px;
    }

    section.banner {
        min-height: 400px;
    }

    .p2p_tacc_benefits_cus::before {
        display: none;
    }

    .trading_tacc_cus::before,
    .trading_tacc_cus::after {
        display: none;
    }
}

@media all and (min-width: 320px) and (max-width: 767px) {

    .p2p_tacc_benefits_cus::before,
    .trading_tacc_cus::before,
    .trading_tacc_cus::after {
        display: none;
    }

    section.banner {
        min-height: 430px;
        padding: 20px 0 50px;
    }

    .banner_content h1 {
        font-size: 20px;
    }
}
</style>
