import axios from "axios";

export default class ApiClass {




    //  kajal 
    // static CAPTCHA_SITE_KEY = "6LfdCW8fAAAA59AIzj80lrhQKQ5O-Qo0EyoaACY5tK";



    
    // static VUE_DOMAIN = "http://192.168.11.72:8081/";
    // static SocketnodeUrl = "ws://192.168.11.72:4020/";
    // static nodeUrl = "http://192.168.11.72:4020/";  
    // static baseUrl = "http://192.168.11.72:8000/api/";
    static currency = {
        USD : 'USD',
        BTC : 'BTC'
    }

    // static nodeUrl = "http://13.235.132.240:4020/";         // demo
    // static SocketnodeUrl = "ws://13.235.132.240:4020/";        // DEMO
    // static baseUrl = "http://13.235.132.240:8000/api/";          // demo
    // static VUE_DOMAIN = "13.235.132.240/";                      // dEMO
    // static nodeImageUrl = "http://192.168.11.72:4020/";
    // static nodeImageUrl = this.nodeUrl


    // aws creds  
    static CAPTCHA_SITE_KEY = "6LfdCW8fAAAAAIzj80lrhQKQ5O-Qo0EyoaACY5tK";
    static VUE_DOMAIN = "https://www.abundancetacc.com/";
    static nodeUrl = "https://node.abundancetacc.com/";
    static SocketnodeUrl = "wss://node.abundancetacc.com/";
    static baseUrl = "https://backend.abundancetacc.com/api/";
    static nodeImageUrl = this.nodeUrl;
    



    //  static nodeUrl = "https://demo-node.abundancetacc.com/";         // demo
    // static SocketnodeUrl = "wss://demo-node.abundancetacc.com/";        // DEMO
    // static baseUrl = "https://demo-backend.abundancetacc.com/api/";          // demo
    // static VUE_DOMAIN = "demo-node.abundancetacc.com/";                      // dEMO

    static nodeImageUrl = this.nodeUrl

    // ******************************* LARAVEL API GET REQUEST *******************************************
    static postRequest(apiUrl, isToken = true, formData = null, headers = null, params = null) {
        return axios.post(this.baseUrl + apiUrl, formData, this.config(isToken, headers, params)).then(result => {
            return result;
        }).catch(error => {
            if (error.response.status == 401) {
                this.unauthenticateRedirect();
            }
        });
    }

    // ********************************* LARAVEL API GET REQUEST **************************************
    static getRequest(apiUrl, isToken = true, headers = null, params = null) {
        return axios.get(this.baseUrl + apiUrl, this.config(isToken, headers, params)).then(result => {
            return result;
        }).catch(error => {
            if (error.response.status == 401) {
                this.unauthenticateRedirect();
            }
        });
    }

    // ******************* LARAVEL API PUT REQUEST WITH IMAGE ************************ */
    static updateFormRequest(apiUrl, isToken = true, formData = null, headers = null, params = null) {
        baseParam = { "_method": "PUT" }
        if (params != null) {
            // var baseParam = $.extend(params, baseParam)
            var baseParam = Object.assign(params, baseParam)
        }
        return axios.post(this.baseUrl + apiUrl, formData, this.config(isToken, headers, baseParam)).then(result => {
            return result;
        }).catch(error => {
            if (error.response.status == 401) {
                this.unauthenticateRedirect();
            }
        });
    }

    // ******************* LARAVEL API PUT REQUEST WITH APPLICATION JSON FORMAT ************************ 
    static updateRequest(apiUrl, isToken = true, formData = null, headers = null, params = null) {

        return axios.put(this.baseUrl + apiUrl, formData, this.config(isToken, headers, params)).then(result => {
            return result;
        }).catch(error => {
            if (error.response.status == 401) {
                this.unauthenticateRedirect();
            }
        });
    }

    // *********************************** LARAVEL API DELETE REQUEST *************************************************** 
    static deleteRequest(apiUrl, isToken = true, headers = null, params = null) {
        return axios.delete(this.baseUrl + apiUrl, this.config(isToken, headers, params)).then(result => {
            return result
        }).catch(error => {
            if (error.response.status == 401) {
                this.unauthenticateRedirect();
            }
        })
    }

    // ******************************* CONFIGURATION OF HEADER AND PARAMETERS ******************************* 
    static config(isToken = true, headers = null, parameters = null) {
        var defaultHeaders = {
            Accept: "application/json"
        };
        var merge = {};
        if (isToken) {
            var token = { Authorization: "Bearer " + localStorage.getItem("token") }
            merge = Object.assign(defaultHeaders, token)
        }
        merge = Object.assign(defaultHeaders, headers)
        return {
            headers: merge,
            params: parameters
        }
    }

    // ********************************* UN[AUTHENTICATION] ERROR ************************************** 
    static unauthenticateRedirect() {
        localStorage.removeItem('user'); // clear user data
        localStorage.removeItem('token'); // remove token
        // this.$store.commit('SET_USER', null);
        location.replace('/login'); // go to login page 
    }

    // ********************************* NODE API GET REQUEST **************************************
    static getNodeRequest(apiUrl, isToken = true, headers = null, params = null) {
        return axios.get(this.nodeUrl + apiUrl, this.config(isToken, headers, params)).then(result => {
            return result;
        }).catch(error => {
            if (error.response.status == 401) {
                this.unauthenticateRedirect();
            }
        });
    }

    // ********************************* NODE API POST REQUEST ************************************** 
    static postNodeRequest(apiUrl, isToken = true, formData = null, headers = null, params = null) {
        return axios.post(this.nodeUrl + apiUrl, formData, this.config(isToken, headers, params)).then(result => {
            return result;
        }).catch(error => {
            if (error.response.status == 401) {
                this.unauthenticateRedirect();
            }
        });
    }
    
      //******************************* Update api ********************************************* */
    static updatenodeRequest(apiUrl, isToken = true, formData = null, headers = null, params = null) {
        return axios.put(this.nodeUrl + apiUrl, formData, this.config(isToken, headers, params))
            .then((result) => {
                return result;
            })
            .catch((error) => {
                if (error.response.status == 401) {
                    this.unauthenticateRedirect();
                }
            });
    }

}