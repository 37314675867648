import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'


import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { Skeletor } from 'vue-skeletor';
import exactMath from "exact-math";
import vue3PhotoPreview from 'vue3-photo-preview';
import 'vue3-photo-preview/dist/index.css';
import VueQrcode from '@chenfengyuan/vue-qrcode';


import mitt from "mitt";
const emitter = mitt();
const mixins = {
    methods: {

        success: function (message) {
            this.$swal({
                title: message,
                position: 'top-end',
                icon: "success",
                toast: true,
                timer: '3000',
                showConfirmButton: false,
            });
        },
        failed: function (message) {
            this.$swal({
                title: message,
                position: 'top-end',
                icon: "error",
                toast: true,
                timer: '3000',
                showConfirmButton: false,
            });
        },
        toastMessage: function (icon, message)  {
            this.$swal({
                title: message,
                position: 'top-end',
                icon,
                toast: true,
                timer: '3000',
                showConfirmButton: false,
            });
        },
        fixafterPoint(value, decimal = 8) {
          value = value.toString();
          if (value && value.includes(".")) {
            let decimal_split = value.split(".");
            let final;
            if(decimal_split[1].length < 8)
            {
              var diff = 8 - decimal_split[1].length
            for (let i = 0;i < diff;i++) 
            {
              value =  value + "0";
            }
               return value;
            }
            final = decimal_split[0] + "." + decimal_split[1].slice(0, decimal);
            return final;
          }
          return value + ".00000000";
        },


    },
};


createApp(App).component(Skeletor.name, Skeletor, VueQrcode.name, VueQrcode).use(store).use(emitter).use(VueSweetalert2).use(vue3PhotoPreview).use(router).use(exactMath).mixin(mixins).mount('#app')