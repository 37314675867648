import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [{
        path: '/',
        name: 'home',
        component: HomeView
    },
    /************************************ Exchange Pages *************************************/
    {
        path: '/exchange',
        name: 'ExchangeView',
        component: () =>
            import ('../views/ExchangeView.vue'),
        meta: { comingSoon:false }
        
    },
    {
        path: '/portfolio',
        name: 'PortfolioView',
        component: () =>
            import ('../views/PortfolioView.vue'),
        meta: { authOnly: true }
    },
    {
        path: '/withdrawhistory',
        name: 'WithdrawHistory',
        component: () =>
            import ('../views/WithdrawHistory.vue'),
        meta: { authOnly: true }
    },
    {
        path: '/deposithistory',
        name: 'DepositHistory',
        component: () =>
            import ('../views/DepositHistory.vue'),
        meta: { authOnly: true }
    },
    /************************************ Auth Pages *************************************/
    {
        path: '/login',
        name: 'LoginView',
        component: () =>
            import ('../views/Auth/LoginView.vue'),
        meta: { guestOnly: true }
    },
    {
        path: '/register',
        name: 'RegisterView',
        component: () =>
            import ('../views/Auth/RegisterView.vue'),
        meta: { guestOnly: true }
    },
    {
        path: '/forgot-password',
        name: 'ForgotPasswordView',
        component: () =>
            import ('../views/Auth/ForgotPasswordView.vue'),
        meta: { guestOnly: true }
    },
    {
        path: '/reset-password',
        name: 'ResetPasswordView',
        component: () =>
            import ('../views/Auth/ResetPasswordView.vue'),
        meta: { guestOnly: true }
    },
    {
        path: '/verifymail',
        name: 'EmailVerifyView',
        component: () =>
            import ('../views/Auth/EmailVerifyView.vue'),
        meta: { guestOnly: true }
    },
    {
        path: '/verification',
        name: 'VerificationView',
        component: () =>
            import ('../views/Auth/VerificationView.vue'),
        

    },

    /***********************Staking*************************/
    // {
    //     path: '/staking',
    //     name: 'StakingView',
    //     component: () =>
    //         import ('../views/StakingView.vue'),
  
    // },
    // {
    //     path: '/subscribedStaking',
    //     name: 'SubscribeView',
    //     component: () =>
    //         import ('../views/SubscribeView.vue'),
            
    // },
    {
        path: '/staking-ruk',
        name: 'StakingOrderView',
        meta: { authOnly: true },
        component: () =>
          import(/* webpackChunkName: "StakingOrderView" */'../views/staking/StakingOrderView.vue'),
        //   meta: { routen: 'earn' }
        meta: { comingSoon:false }
      },
      {
        path: '/staking-history',
        name: 'StakingHistory',
        component: () => import(/* webpackChunkName: "StakingHistory" */ '../views/staking/StakingHistory.vue'),
        meta: { comingSoon:false }
      },
      {
        path: '/staking',
        name: 'StakingTableView',
        component: () => import( /* webpackChunkName: "StakingTableView"  */ '../views/staking/StakingTableView.vue'),
        // meta: { routen: 'earn' }
        meta: { comingSoon:false }
      },
      {
        path: '/stake',
        name: 'StakeView',
        component: () => import(/* webpackChunkName: "StakeView" */ '../views/staking/StakeView.vue'),
        meta: { comingSoon:false }
      }, 
    /************************************ P2P Pages *************************************/
    // {
    //     path: '/p2p/p2porderview',
    //     name: 'P2POrderView',
    //     component: () =>
    //         import ('../views/P2P/P2POrderView.vue'),
    //         // meta: { comingSoon:true }

    // },
    {
        path: '/p2p/p2porder',
        name: 'P2pOrder',
        component: () =>
            import ('../views/P2P/P2pOrder.vue'),
            meta: { comingSoon:false }


    },
    {
        path: '/p2p/p2pexecuteorder',
        name: 'P2PExecuteOrder',
        component: () =>
            import ('../views/P2P/P2PExecuteOrder.vue'),
            meta: { comingSoon:false }
    },

    {
        path: '/privacypolicy',
        name: 'PrivacyPolicy',
        component: () =>
            import ('../views/PrivacyPolicy.vue'),
            meta: { comingSoon:false }
    },
   
    {
        path: '/P2P/OrderhistoryView',
        name: 'OrderhistoryView',
        component: () =>
            import ('../views/P2P/OrderhistoryView.vue'),
            meta: { comingSoon:false }
    },
    {
        path: '/p2p-sell-order',
        name: 'P2PSellModal',
        meta: { authOnly: true },
        component: () =>
            import ('../views/P2P/P2PSellModal.vue'),
            meta: { comingSoon:false }
    },
    {
        path: '/p2p-buy-order',
        name: 'P2PBuyModal',
        meta: { authOnly: true },
        component: () =>
            import ('../views/P2P/P2PBuyModal.vue'),
            meta: { comingSoon:false }

    },
    /************************************ Settings Pages *************************************/
    {
        path: '/profile',
        name: 'ProfileView',
        component: () =>
            import ('../views/Settings/ProfileView.vue'),
        meta: { authOnly: true }
    },
    {
        path: '/change_password',
        name: 'ChangePassword',
        component: () =>
            import ('../views/Settings/ChangePasswordView.vue'),
        meta: { authOnly: true }
    },
    {
        path: '/kyc-settings',
        name: 'KYCSettingsView',
        component: () =>
            import ('../views/Settings/KYCSettingsView.vue'),
        meta: { authOnly: true }
    },
    {
        path: '/bank-detail',
        name: 'BankDetailView',
        component: () =>
            import ('../views/Settings/BankDetailView.vue'),
        meta: { authOnly: true }
    },
    {
        path: '/two-factor',
        name: 'TwoFactorView',
        component: () =>
            import ('../views/Settings/TwoFactorView.vue'),
        meta: { authOnly: true }
    },
    {
        path: '/referral',
        name: 'ReferralLink',
        component: () =>
            import ('../views/Settings/ReferralLink.vue'),
        meta: { authOnly: true }
    },
    {
        path: '/fee',
        name: 'FeeView',
        component: () =>
            import ('../views/Settings/FeeView.vue'),
        meta: { authOnly: true }
    },
    {
        path: '/activity-log',
        name: 'ActivityLogView',
        component: () =>
            import ('../views/Settings/ActivityLogView.vue'),
        meta: { authOnly: true }
    },
    {
        path: '/trading-report',
        name: 'TradingReport',
        component: () =>
            import ('../views/Settings/TradingReportView.vue'),
        meta: { authOnly: true }
    },
    {
        path: '/support',
        name: 'SupportView',
        component: () =>
            import ('../views/Settings/SupportView.vue'),
        meta: { authOnly: true }
    },
    {
        path: '/query',
        name: 'QueryView',
        component: () =>
            import ('../views/Settings/QueryView.vue'),
        meta: { authOnly: true }
    },
    {
        path: '/ticket-list',
        name: 'TicketListView',
        component: () =>
            import ('../views/Settings/TicketListView.vue'),
        meta: { authOnly: true }
    },
    {
        path: '/ticket-view',
        name: 'TicketView',
        component: () =>
            import ('../views/Settings/TicketView.vue'),
        meta: { authOnly: true }
    },

    {
        path: '/coming-soon',
        // name: 'ComingSoon',
        component: () =>
            import  ('../views/ComingSoon.vue')
    },
    {
        path: '/maintenance',
        name: 'Maintenance',
        component: () =>
            import ('../views/Maintenance.vue')
    },
    {
        path: '/not-found',
        name: 'NotFound',
        component: () =>
            import ('../views/NotFound.vue')
    },
    
    //* ***********************          👻 launchpad 👻  *******************************         */ 

    {
        path: '/launchpad',
        name: 'LaunchpadView',
        component: () =>
            import ('../views/Launchpad/LaunchpadView.vue'),
            meta:{  comingSoon:false}
    },
    {
        path: '/launchpad-blog',
        name: 'LaunchpadBlog',
        component: () =>
            import ('../views/Launchpad/LaunchpadBlog.vue'),
    },
    {
        path: '/launchpad-token',
        name: 'LaunchpadBlogToken',
        component: () =>
            import ('../views/Launchpad/LaunchpadBlogToken.vue'),
    },
    {
        path: '/coming-soon',
        name: 'ComingSoon',
        component: () =>
            import ('../views/ComingSoon.vue')
    },


 
 
 
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    },
})

function isLoggedIn() {
    return localStorage.getItem('token');
}

router.beforeEach((to, from, next) => {


    console.log({to}, to.matched.length)

        if(to.matched.length==0){
          
                next({
                    path: '/not-found',
                })
        }
        if( to.meta.comingSoon){
            next({
                path: '/coming-soon',
            })
        }
    if (to.matched.some(record => record.meta.authOnly)) {
        
        if (!isLoggedIn()) {

            next({
                path: '/login',
            })
        } else {
            

            next()
        }
    } else if (to.matched.some(record => record.meta.guestOnly)) {
        
        if (isLoggedIn()) {
            next({
                path: 'exchange',
            })
        } else {

            next()
        }
    } else {
        next();

    }
})
export default router